import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {AMPStatus, UnSub} from 'amp-ng-library';
import { AudioLibraryService } from 'amp-ng-library';
import { LibraryModalDelegate } from 'amp-ng-library';

import { AMPPlayEntityTranslation, PlaybackContent } from 'amp-ng-library';
import {takeUntil} from 'rxjs/operators';
import { PlaybackContentVisibility } from 'amp-ng-library';
import { FolderType, MediaType } from 'amp-ng-library';
import { LibraryConfigService } from 'amp-ng-library';
import {AMPObjectType} from 'amp-ng-library';
import {PlaybackContentPlaylist, PlaybackDataService} from 'amp-ng-playlists';
import {GenerateIcon} from 'amp-ng-playlists';

// moved this into shared component because it requires/needed to know of the playlist model and library doesn't need to know of the playlist
@Component({
  selector: 'app-library-modal',
  templateUrl: './library-modal.component.html',
  styleUrls: ['./library-modal.component.scss']
})
export class LibraryModalComponent extends UnSub implements OnInit  {
  @Input() public name;
  @Input() public contentItems: [any];
  @Input() public mediaType: MediaType;
  @Input() public playlistType: number;
  @Input() public libraryDelegate: LibraryModalDelegate;

  private readonly defaultCssName = 'amp-save-lg'
  private readonly defaultCancelCssName = 'amp-cancel-lg'
  public savingNewPlaylist: boolean = false


  CreatePlaylistForm = new FormGroup({
    PlaylistName: new FormControl({value: '', disabled: this.savingNewPlaylist}),
  });

  constructor(
    public activeModal: NgbActiveModal,
    public audioLibraryService: AudioLibraryService,
    public translate: TranslateService,
    private configService: LibraryConfigService,
    private playbackDataService: PlaybackDataService
    ) { super() }


  ngOnInit(): void {
  }

  private getButtonClassName(value: number): string {
    return value === 0 ? this.defaultCssName : this.defaultCancelCssName
  }

  // value 0 == create button, value 1 == cancel button
  getCssClassName(value: number): string {
      return (!this.savingNewPlaylist)
          ? (value === 0 && this.hasPlaylistName())
              ? this.getButtonClassName(value)
              : (value === 1)
                  ? this.getButtonClassName(value)
                  : 'disabled'
          : 'disabled'
  }

  get playlistName():string {
    return this.CreatePlaylistForm.controls.PlaylistName.value.trim()
  }

  hasPlaylistName(): boolean {
    return this.playlistName.length > 0
  }

  createPlaylist() {

    if (!this.hasPlaylistName()) {
      // console.log("being reached")
      return
    }
    const items = [];
    for (const ci of this.contentItems) {
      items.push(ci.identifier);
    }

    const newThumbnail = GenerateIcon.makeIconUrl(this.mediaType, AMPObjectType.AMP_PLAYLIST, GenerateIcon.makeRandomThumbnail());
    const defaultTranslation = new AMPPlayEntityTranslation(
      0,
      FolderType.PLAYLIST,
      1,
      'en',
      '',
      ''
  );
    const newPlaylist = new PlaybackContentPlaylist(0, BigInt(0), 1, AMPStatus.LIVE, 0,
      this.playlistName, this.mediaType,
      this.playlistType, 0, 0,
        items, 0, false, undefined, 1, [], [], false,
        PlaybackContentVisibility.private, [defaultTranslation], newThumbnail);


    this.savingNewPlaylist = true
    this.CreatePlaylistForm.get('PlaylistName').disable()


    this.playbackDataService.createContent(newPlaylist, AMPObjectType.AMP_PLAYLIST, this.mediaType)
        .pipe(
            takeUntil(this.unsubscribe$)
        )
        .subscribe(
            (playlistsResponse: PlaybackContent[]) => {
                if (this.libraryDelegate !== undefined) {
                    this.configService.alert.success(`${this.translate.instant('added-successfully')} ${newPlaylist.name}`);
                }
                this.activeModal.close(playlistsResponse);
                },
            (errorResponse) => {
                if (errorResponse.status === 0) {
                    this.configService.alert.error(this.translate.instant('unable-to-connect-to-the-server'));
                    return;
                }
                const response = JSON.parse(errorResponse.error);
                this.configService.alert.error(response.responseData);
                this.close()
            },
            () => {
                this.CreatePlaylistForm.get('PlaylistName').enable()
                this.savingNewPlaylist = false
            })
  }


  close() {
    this.activeModal.close(this)
  }

}

