<div class="container-fluid vh-100 no-gutters root-container">
    <div class="row bg-nav page-structure-navbar {{(isMobile || smallScreen) ? 'mobile' : ''}}">
        <div class="col-xl-3 col-0 my-auto logo d-xl-block d-md-none d-sm-block">
            <a href="http://www.atmosphere365.com/atmosphere365">
                <img src="../assets/img/atmosphere365-logo-w3.png" alt="Atmosphere Logo"
                     class="img-fluid logo"/>
            </a>
        </div>
        <div class="col-xl-9 col-12 p-0">
            <nav *ngIf="account.isAuthenticated()"
                 class="col-12 navbar navbar-expand-md navbar-light bg-faded amp-text {{mobileNavBar ? 'mobile show' : ''}}">
                <button class="navbar-toggler" type="button" data-toggle="collapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
                    <!-- <span class="navbar-toggler-icon"></span> -->
                    <span class="fa fa-bars" aria-hidden="true"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent" [appDynamicHostListener]="mobileNavBar"  (clickOutsideListenerTags)="clickedOutsideNav($event)">
                    <!--            Tabs switch between admin and client tabs for admin/user mode-->
                    <div *ngIf="account.isAdmin()">
                        <div *ngIf="account.isUserMode()">
                            <!-- User Tabs -->
                            <app-client-tabs></app-client-tabs>
                        </div>
                        <div *ngIf="account.isAdminMode()">
                            <!-- Admin Tabs -->
                            <admin-tabs></admin-tabs>
                        </div>
                        <div *ngIf="account.isContentMode()">
                            <!-- App Dam Tabs -->
                            <app-dam-tabs></app-dam-tabs>
                        </div>
                    </div>

                    <div *ngIf="!account.isAdmin()">
                        <!--                <client-tabs *ngIf="account.isClient() || account.isVenue()"></client-tabs>-->
                        <app-client-tabs *ngIf="account.isClient() || account.isVenue()"></app-client-tabs>
                        <reseller-tabs *ngIf="account.isReseller()"></reseller-tabs>
                        <cp-tabs *ngIf="account.isContentProvider() && !account.isReseller()"></cp-tabs>
                    </div>


                    <ul class="navbar-nav ml-auto mx-3 {{!isMobile ? 'float-right' : ''}}">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle amp-tab d-flex align-items-center" href="#" id="navbarDropdown" role="button"
                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                               <img class="img-fluid" src="assets/icons/user.svg" style="width: 18px; margin-right: 5px;">
                               <!-- <app-svg-icon iconName="user" fillColor="#fff" size="18px"></app-svg-icon> -->
                                {{getAccountName()}}
                            </a>
                            <div class="dropdown-menu account-dropdown {{mobileNavBar ? '__mobile' : ''}}" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item d-flex" routerLink="/details" routerLinkActive="active"
                                   [routerLinkActiveOptions]="{exact: true, applyToChildren: true}">
                                   <img class="img-fluid" src="assets/icons/user.svg">
                                     <!-- <app-svg-icon iconName="user2" fillColor="#fff" size="18px"></app-svg-icon> -->
                                    {{ 'account' | translate }}
                                </a>
                                <a class="dropdown-item d-flex" (click)="logout()" routerLink="/login">
                                    <img class="img-fluid" src="assets/icons/log-out.svg">
                                    <!-- <app-svg-icon iconName="log-out" fillColor="none" size="18px"></app-svg-icon> -->
                                    {{ 'log-out' | translate }}
                                </a>
                                <a *ngIf="userModeAvailable()" class="dropdown-item" (click)="account.enterUserMode()"
                                   routerLink="/dashboard">
                                    User Mode
                                </a>
                                <a *ngIf="contentModeAvailable()" class="dropdown-item"
                                   (click)="enterContentMode()"
                                   routerLink="/library/audio">
                                    Content Mode
                                </a>
                                <a *ngIf="adminModeAvailable()" class="dropdown-item" (click)="account.enterAdminMode()"
                                   routerLink="/admin">
                                    Admin Mode
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
        <div class="col-xl-12 p-0">
            <app-notices></app-notices>
        </div>
    </div>

    <div class="page-structure-content {{(account.isAdmin()) ? 'page-structure-content--admin' : ''}}">
        <alert></alert>
        <router-outlet></router-outlet>
    </div>

    <ng-container #cookieDetailContainer></ng-container>
</div><!-- container-fluid -->
