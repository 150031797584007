import { Component, OnInit } from '@angular/core';
import {AMPContentItem, AMPFolder} from 'amp-ng-library';
import { AudioLibraryService } from 'amp-ng-library';
import { LibraryComponentService } from 'amp-ng-library';
import { ListItem, ListItemType } from 'amp-ng-library';
import { AlertService } from 'amp-ng-library';
import { TranslateService } from '@ngx-translate/core';
import { MediaType } from 'amp-ng-library';
import * as _ from 'lodash';
import { UnSub } from 'amp-ng-library';
import {map, takeUntil} from 'rxjs/operators';
import { PlaybackContent } from 'amp-ng-library';
import { NestedMasterViewListMediator } from 'amp-ng-library';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LibraryModalDelegate } from 'amp-ng-library';
import { LibraryConfigService } from 'amp-ng-library';
import  {cloneDeep} from 'lodash'
import {AMPObjectType} from 'amp-ng-library';
import {AMPEventType} from 'amp-ng-library';
import {NestableListDelegate} from 'amp-ng-playlists';
import {IconType, PlaybackContentPlaylist, PlaybackDataService} from 'amp-ng-playlists';
import {processPlaybackContentCollection} from 'amp-ng-playlists';

@Component({
	selector: 'app-lib-drop-down-wrapper',
	templateUrl: './lib-drop-down-wrapper.component.html',
	styleUrls: ['./lib-drop-down-wrapper.component.scss']
})
export class LibDropDownWrapperComponent extends UnSub implements OnInit, NestableListDelegate, LibraryModalDelegate {
	playlistFolders: Array<AMPFolder> = [];
	playlistListItems: Array<ListItem>
	playlists: Array<PlaybackContentPlaylist> = [];
	contentItems: Array<any>;

	listMediator = new NestedMasterViewListMediator()

	constructor(
		private audioLibraryService: AudioLibraryService,
		private playbackDataService: PlaybackDataService,
		private libraryMenuService: LibraryComponentService,
		private configService: LibraryConfigService,
		private translate: TranslateService,
		private alert: AlertService,
		private modalService: NgbModal,

	) {
		super()
	}
	success() {
		Promise.all([this.loadPlaylistFolders(), this.getPlaylists()]).then(res => {
			this.buildPlaylistList();
		});
	}
	isContentMode(): boolean {
		throw new Error('Method not implemented.');
	}

	ngOnInit(): void {
		this.contentItems = this.libraryMenuService.getContentItems();
		this.libraryMenuService.updateContentItems.subscribe(res => {
			this.contentItems = this.libraryMenuService.getContentItems();
		})
		this.loadPlaylistFolders().then(
			() => {
				this.getPlaylists().then(
					() => {
						this.buildPlaylistList();
					}
				);
			}
		);
	}

	get multiSelectListMediator() {
		return this.libraryMenuService.getMultiSelectListMediator();
	}

    loadPlaylistFolders() {
        return this.audioLibraryService.fetchFoldersByTypeIdentifier(1, this.libraryMenuService.mediaType).toPromise().then(res => {
            return this.playlistFolders = res;
        });

    }

	open(contentName: string) {
		const modalComponent = this.configService.getConfig().libraryModal
		let options: NgbModalOptions = {
			centered: true,
			modalDialogClass: ''
		}
		if (this.configService.isMobile()) {
			options.modalDialogClass = 'mobile'
		}
		const modalRef = this.modalService.open(modalComponent, options);
		modalRef.componentInstance.name = contentName;
		modalRef.componentInstance.playlistType = 1;
		modalRef.componentInstance.mediaType = this.libraryMenuService.mediaType;
		modalRef.componentInstance.libraryDelegate = this;
		if (!this.multiSelectListMediator || this.multiSelectListMediator.getSelected().length == 0) {
			modalRef.componentInstance.contentItems = this.contentItems;
		} else {
			var selectedItems = [];
			this.multiSelectListMediator.getSelected().forEach(index => { selectedItems.push(this.contentItems[index]) });
			modalRef.componentInstance.contentItems = selectedItems;
		}
		modalRef.result.then((result) => {
			this.updateLibPlaylistDropDown(result);
		})
	}

	getPlaylists() {
		return this.audioLibraryService.fetchPlaylists(this.libraryMenuService.mediaType).toPromise().then(res => {
			this.playlists = res as PlaybackContentPlaylist[];
		}, error => {
			if (error.status === 0) {
				this.alert.error(this.translate.instant('unable-to-connect-to-the-server'));
				return;
			}
			const response = JSON.parse(error.text());
			this.alert.error(response.responseData);
		});
	}

	buildPlaylistList() {
		const iconType = this.libraryMenuService.mediaType === MediaType.VIDEO ? IconType.VIDEO_PLAYLIST : IconType.AUDIO_PLAYLIST
		this.playlistListItems = processPlaybackContentCollection(this.playlistFolders, this.playlists.filter(playlist => playlist.playlistType === 1), iconType)
	}

	listItemSelected(event: any, listItem: ListItem) {
		// event.stopPropagation()
		const listItemType: ListItemType = listItem.isFolder ? ListItemType.FOLDER : ListItemType.CONTENT

		switch (listItemType) {
			case ListItemType.CONTENT:
				this.handlePlaylistDropdownClick(listItem);
				break;

			case ListItemType.FOLDER:
				this.handleFolderSelected(listItem);
				break
		}
		this.listMediator.setSelectedListItem(listItem, event.nestedDepth)
	}

	addToPlaylist(playlist: PlaybackContentPlaylist) {

		// Permanent playlist
        if (playlist.movieIdentifiers.length >= 5 && playlist.permanent && playlist.mediaType === MediaType.AUDIO) {
			this.alert.error(this.translate.instant('permanent-playlist-rules'));
			return;
		}

        const ADD_ALL = !this.multiSelectListMediator || this.multiSelectListMediator.getSelected().length == 0

        const playlistCopy = cloneDeep(playlist)

        this.addMovieIdentifiersToPlaylist(playlistCopy)
            .then(
                (playlistCopy: PlaybackContentPlaylist) => {
                    // Append identifiers
                    if (ADD_ALL) {
                        this.contentItems.forEach(item => playlistCopy.movieIdentifiers.push(item.identifier))
                    }
                    else {
                        // Add selected
                        const selectedIdentifiers = this.multiSelectListMediator.getSelectedIdentifiers()
                        selectedIdentifiers.forEach(identifier => playlistCopy.movieIdentifiers.push(identifier))
                    }

                    // Push edited object
                    this.playbackDataService.editContent(playlistCopy, AMPObjectType.AMP_PLAYLIST, this.libraryMenuService.mediaType)
                        .pipe(
                            takeUntil(this.unsubscribe$)
                        )
                        .subscribe(
                            (playlistsResponse: PlaybackContent[]) => {
                                this.playlists = playlistsResponse as PlaybackContentPlaylist[]
                                this.multiSelectListMediator.clearSelection();
                                this.alert.success(`${ this.translate.instant('added-successfully') } ${ playlist.name }`);
                                this.buildPlaylistList();
                            },
                            error => {
                                console.log(error);
                                if (error.status === 409) {
                                    this.alert.error(this.translate.instant('item-out-of-date'));
                                    return;
                                } else if (error.status === 0) {
                                    this.alert.error(this.translate.instant('unable-to-connect-to-the-server'));
                                    return;
                                } else if (error.status === 400) {
                                    this.alert.error(this.translate.instant('permanent-playlist-rules'));
                                    return;
                                }
                            });
                }
            )
	}

	addMovieIdentifiersToPlaylist(playlist: PlaybackContentPlaylist): Promise<PlaybackContentPlaylist> {

		let productSet

		if (this.configService.accountInfo.isAdmin) {
			productSet = []
		}

		return this.playbackDataService.fetchPlaylistMovies(playlist, productSet)
			.pipe(
				map(
					(movies: Array<AMPContentItem>) => {
						const movieIdentifiers = movies.map(movie => movie.identifier);
						playlist.movieIdentifiers = movieIdentifiers;
						return playlist;
					}
				)
			)
			.toPromise();
	}
	

	handlePlaylistDropdownClick(listItem) {
		this.addToPlaylist(listItem.getInnerModel());
	}

	private handleFolderSelected(listItem: ListItem) {
		const folderEventType = listItem.isSelected() ? AMPEventType.CLOSE_FOLDER : AMPEventType.OPEN_FOLDER
		const allFolderListItems = this.getAllFolderListItems()

		if (folderEventType == AMPEventType.OPEN_FOLDER) {
			this.openFolder(listItem, allFolderListItems);

		} else {
			this.closeFolder(listItem, allFolderListItems);
		}
	}

	private closeFolder(folderListItem: ListItem, allFolders: ListItem[]) {
		// when deselected, navigate to parent folder
		const branchFolderId = folderListItem.getInnerModel().folder.folderId

		// close all child folders, we can assume no other folders of lower level should be open
		allFolders.map(folder => {
			if (folder.nestedDepth > folderListItem.nestedDepth) {
				folder.isFocused = false
				return folder
			}
		})
		folderListItem.isFocused = false

		// set focus to parent
		if (branchFolderId !== 0) {
			const allFolderListItems = this.getAllFolderListItems()
			const parent = allFolderListItems.find(folder => folder.id === folderListItem.folderId)
			parent.isFocused = true
		}

		this.setColoured(folderListItem, false)
	}

	private openFolder(folderListItem: ListItem, allFolders: ListItem[]) {
		// Close all other folders that are on the same level or lower
		allFolders.map(folder => {
			if (folder.nestedDepth <= folderListItem.nestedDepth) {
				folder.isFocused = false
				return folder
			}
		})
		folderListItem.isFocused = true
		this.setColoured(folderListItem, true)
	}

	private setColoured(listItem: ListItem, coloured: boolean) {
		const fullList = this.flattenList(this.playlistListItems)
		const folderList = fullList.filter(item => item.isFolder)
		const contentList = fullList.filter(item => !item.isFolder)

		// uncolour everything
		fullList.map(item => item.isColoured = false)

		// if selecting, colour it, if deselecting, colour parent unless at root level
		if (coloured) {
			const itemToColour = listItem.isFolder ? folderList.find(item => item.id === listItem.id) : contentList.find(item => item.id === listItem.id)
			itemToColour.isColoured = true
		} else {
			if (listItem.folderId && listItem.folderId !== 0) {
				const itemToColour = folderList.find(item => item.id === listItem.folderId)
				itemToColour.isColoured = true
			}
		}
	}

	private flattenList(list: any[]): any[] {
		return _.flatMapDeep(list, item => {
			if (item.hasNestedItems()) {
				return [item, ...this.flattenList(item.getNestedItems())]
			} else {
				return item
			}
		})
	}

	private getAllFolderListItems(): ListItem[] {
		const flattenedList = this.flattenList(this.playlistListItems)
		const folderList = flattenedList.filter((item: ListItem) => item.getInnerModel().isFolderListItemModel)
		return folderList
	}

	private updateLibPlaylistDropDown(playlist: PlaybackContentPlaylist[]) {
		if (!playlist) { return }
		this.playlists = playlist;
		this.buildPlaylistList()
	}

}
