 <ul class="navbar-nav mr-auto d-flex justify-content-between">

   <li class="nav-item  p-0">
     <a class="amp-tab" routerLink="admin/adminaccounts" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true, applyToChildren: true}">ACCOUNTS</a>
   </li>

   <!-- <li class="nav-item  p-0">
    <a class="amp-tab" routerLink="admin/adminlicences" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true, applyToChildren: true}">LICENSES</a>
  </li> -->

     <li class="nav-item active">
         <a class="amp-tab" routerLink="admin/adminorders" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true, applyToChildren: true}">ORDERS</a>
     </li>

     <li class="nav-item active">
      <a class="amp-tab" routerLink="admin/planorders" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">Product Plan Orders</a>
    </li>

     <li class="nav-item active">
         <a class="amp-tab" routerLink="admin/admincontrol" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true, applyToChildren: true}">UPDATES</a>
     </li>

     <li class="nav-item active">
      <a class="amp-tab" routerLink="admin/adminplays" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true, applyToChildren: true}">MOVIES</a>
    </li>

     <li class="nav-item active">
         <a class="amp-tab" routerLink="admin/admindebug" routerLinkActive="isActive('/admin/admindebug')" [class.active]="isActive('/admin/admindebug')" [routerLinkActiveOptions]="{exact: true, applyToChildren: true}">CLIENTS</a>
     </li>

     <li class="nav-item active">
      <a class="amp-tab" routerLink="admin/email" routerLinkActive="isActive" [routerLinkActiveOptions]="{exact: true, applyToChildren: true}">MISCELLANEOUS</a>
     </li>

 </ul>
